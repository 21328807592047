<template>
    <div>
        <vue-good-table
            class="monitor"
            :columns="columns"
            :rows="rows"
            @on-row-click="onRowClick"
            @on-selected-rows-change="selectionChanged"
            :search-options="{
                enabled: true,
            }"
            :pagination-options="{ enabled: true, perPage: 10, perPageDropdown: [10, 15, 20, 30, 50] }"
        >
            <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <div v-if="props.row[props.column.field][0] == 'M'" class="content-missing" v-b-tooltip.hover.v-dark title="Missing">
                    {{ props.row[props.column.field][0] }}
                </div>
                <div v-else-if="props.row[props.column.field][1] == 'late'" class="content-late" v-b-tooltip.hover.v-dark title="Late">
                    {{ props.row[props.column.field][0] }}
                </div>
                <div v-else-if="props.row[props.column.field][1] == 'early'" class="content-early" v-b-tooltip.hover.v-dark title="Early">
                    {{ props.row[props.column.field][0] }}
                </div>
                <div v-else-if="props.row[props.column.field][1] == 'unexpected'" class="content-unexpected" v-b-tooltip.hover.v-dark title="Unexpected">
                    {{ props.row[props.column.field][0] }}
                </div>
                <div v-else-if="props.column.field == 'progress' || props.column.field == 'station_id' || props.column.field == 'station_name'" style="display: block; text-align: center; white-space: nowrap;">
                    {{ props.row[props.column.field][0] }}
                </div>
                <div v-else style="display: block; text-align: center; white-space: nowrap; font-size: 14px !important;">
                    {{ props.row[props.column.field][0] }}
                </div>
                <!-- <div v-if="props.column.field == 'progress'">
                    {{ props.row[props.column.field] }}%
                </div> -->
                <!-- <div
                    v-if="
                        props.column.renderFlag == true &&
                        props.column.field != 'progress'
                    "
                    class="icons-visual"
                >
                    <a-icon
                        type="check"
                        v-if="props.row[props.column.field] == '1'"
                        style="color: #00ff00;"
                    />
                    <a-icon
                        type="close"
                        v-if="props.row[props.column.field] == '0'"
                        style="color: #ff0000;"
                    />
                    <a-icon
                        type="stop"
                        v-if="props.row[props.column.field] === '-'"
                    />
                </div> -->
            </template>
        </vue-good-table>
        <div style="padding-top: 10px; padding-bottom: 10px; text-align: right;">
            <!-- <a-button class="btn-success" icon="download" @click="onExportExcel">
                Export Excel
            </a-button> -->
            <!-- <download-excel
        class="btn btn-success"
        :data="rows"
        :fields="field_xlsx"
        :worksheet="export_name"
        :name="export_name"
        :header="export_name"
      >
        Download
      </download-excel> -->

            <!-- <ExcelComponent
                class="btn btn-success"
                :data="rows"
                :fields="field_xlsx"
                :worksheet="export_name"
                :name="export_name"
                :header="export_name"
                type="xls"
            >
                Download
            </ExcelComponent> -->
        </div>
        <div>
            <!-- <a-button style="padding-top: 10px; padding-bottom: 10px;" @click="asexportExcel" class="btn btn-success" :sumberdata="sumberdata">Download</a-button> -->
            <b-button v-on:click="asexportExcel" variant="success" class="ml-sm-6" size="" :sumberdata="sumberdata">Download</b-button>
            <!-- <HelloWorld /> -->
        </div>
        <!-- <a-button @click="cekdata" class="btn-success">dsadas</a-button> -->
    </div>
</template>

<script>
import {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BListGroup,
    BListGroupItem,
    BCard,
    BCardHeader,
    BCardTitle,
    BTabs,
    BTab,
    BFormDatepicker,
    BAvatar,
    BBadge,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BTable,
    BTooltip,
    VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
// import HelloWorld from "./HelloWorld.vue";
import moment from "moment";
moment.locale("id");

export default {
    name: "DinamicTable",
    props: {
        DATAS: Object,
        PERIODE: Object,
        ExportExcelName: String,
        BALAI: Object,
        RESOURCE: Object,
    },
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardTitle,
        BFormGroup,
        VueGoodTable,
        saveExcel,
        BButton,
        // HelloWorld,
        ExcelJS,
        saveAs,
        BTooltip,
        VBTooltip,
    },
    directives: {
        "b-tooltip": VBTooltip,
    },
    mounted() {
        // console.log("this.columns", this.columns);
        // console.log("PERIODE.PERIODE", this.PERIODE.substring(0, 4))
        // console.log("0000000000DATAS  00000", this.DATE_SELECTED);
        // console.log("route))))", this.$route.path);
    },
    methods: {
        callParent() {
            this.$emit("onAction", { type: "edit" });
        },
        onRowClick(params) {
            this.$emit("onRowClick", params);
        },
        selectionChanged(params) {
            this.$emit("selectionChanged", params);
        },
        refreshDataChild(message) {
            this.columns = message.columns;
            this.rows = message.DATAS;
            this.rowsXls = message.XLSData;
            console.log("refreshDataChild", message);
            // this.sumberdata = message.RESOURCE;
            // this.balaidata = message.BALAI;
        },
        generateTable: function generateTable(message) {
            this.columns = message.columns;
            this.rows = message.DATAS;
            this.rowsXls = message.XLSData;
            console.log("generateTable", message);
            // this.sumberdata = message.RESOURCE;
            // this.balaidata = message.BALAI;
        },
        onExportExcel() {
            var columnCustome = [];
            for (var i in this.columns) {
                var infoCol = this.columns[i];
                columnCustome.push({
                    field: infoCol.field,
                    title: infoCol.label,
                });
            }
            saveExcel({
                data: this.rows,
                fileName: this.export_name,
                columns: columnCustome,
            });
        },
        cekdata() {
            console.log("filed : ", this.field_xlsx);
        },
        moment,

        asexportExcel() {
            console.log("balaidata", this.BALAI);
            console.log("sumberdata", this.RESOURCE);

            const workbook = new ExcelJS.Workbook();
            const filename = "sheet";

            let worksheet = workbook.addWorksheet(filename, {
                // pageSetup: {
                //   horizontalCentered: true,
                //   columns: 8,
                //   verticalCentered: true,
                //   paperSize: 9,
                //   orientation: "portrait",
                //   margins: {
                //     left: 0.3149606,
                //     right: 0.3149606,
                //     top: 0.3543307,
                //     bottom: 0.3543307,
                //     header: 0.3149606,
                //     footer: 0.3149606,
                //   },
                // },
            });
            // workbook.xlsx.writeFile("fileName");

            worksheet.getCell("A6:A7").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            worksheet.mergeCells("A6:A7");

            const cellStationId = worksheet.getCell("A6");
            cellStationId.value = "Station Id";
            // worksheet.columns = [{ key: "A", width: 7 }];

            worksheet.mergeCells("B6:B7");
            worksheet.getCell("B6:B7").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };

            const cellStationName = worksheet.getCell("B6");
            cellStationName.value = "Station Name";
            // worksheet.columns = [{ key: "B", width: 250 }];

            // worksheet.columns = [{ key: "C", width: 200 }];

            // worksheet.mergeCells("AK6:AK7");
            // worksheet.getCell("AK6:AK7").alignment = {
            //   horizontal: "center",
            //   vertical: "middle",
            //   wrapText: true,
            // };
            // const cellPenyebabDataTidakMasuk = worksheet.getCell("AK6");
            // cellPenyebabDataTidakMasuk.value = "Penyebab Data Tidak Masuk";

            if (this.RESOURCE.LABEL === "METAR SPECI") {
                const headerRowHasil = worksheet.getRow(1);
                headerRowHasil.height = 30;
                worksheet.mergeCells("A1:BA1");
                worksheet.mergeCells("C6:C7");
                worksheet.getCell("C6:C7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };

                // worksheet.columns.forEach(column => {
                //   column.border = {
                //     top: { style: "thin" },
                //     left: { style: "thin" },
                //     bottom: { style: "thin" },
                //     right: { style: "thin" }
                //   };
                // });

                const cellJamPengamatan = worksheet.getCell("C6");
                cellJamPengamatan.value = "Jam Pengamatan";
                worksheet.mergeCells("D6:D7");
                worksheet.getCell("D6:D7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                const cellPeriode = worksheet.getCell("D6");
                cellPeriode.value = "Periode";

                headerRowHasil.getCell(1).value = "Hasil Monitoring Data Bulan" + moment(this.PERIODE).format(" MMMM  YYYY");
                headerRowHasil.getCell(1).font = {
                    name: "Arial Black",
                    size: 16,
                    bold: true,
                    color: "#000",
                };
                headerRowHasil.getCell(1).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };

                const headerRowBalai = worksheet.getRow(3);
                headerRowBalai.height = 30;
                worksheet.mergeCells("A3:BA3");

                headerRowBalai.getCell(1).value = "Balai Besar MKG " + this.BALAI.LABEL;

                headerRowBalai.getCell(1).font = {
                    name: "Arial Black",
                    size: 16,
                    verticalAlignment: "center ",
                    bold: true,
                    color: "#000",

                    // color: { argb: "FF0000FF" },
                };
                headerRowBalai.getCell(1).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };

                const headerRowTipe = worksheet.getRow(5);
                headerRowTipe.height = 20;
                worksheet.mergeCells("A5:C5");

                headerRowTipe.getCell(1).value = "Tipe FORM: " + this.RESOURCE.LABEL;
                worksheet.getCell("A5:C5").font = {
                    name: "Arial Black",
                    size: 11,
                    color: "red",
                    verticalAlignment: "left",
                    horizontalAlignment: "left",
                };
                worksheet.getCell("A5:C5").alignment = {
                    horizontal: "left",
                    vertical: "middle",
                    wrapText: true,
                };
                // worksheet.getCell("A5:C5").alignment = {this.RESOURCE.LABEL
                //   horizontal: "left",
                //   vertical: "middle",
                //   wrapText: true,
                // };

                const cellJam = worksheet.getCell("E6");
                worksheet.mergeCells("E6:AZ6");

                worksheet.getCell("E6:AZ6").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                cellJam.value = "Jam";
                worksheet.mergeCells("BA6:BA7");

                worksheet.getCell("BA7").value = "Progress";

                worksheet.getCell("BA6:BA7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.getColumn(53).numFmt = '#,#0.00"%"';

                worksheet.getCell("E7").value = "00:00";
                worksheet.getCell("F7").value = "00:30";
                worksheet.getCell("G7").value = "01:00";
                worksheet.getCell("H7").value = "01:30";
                worksheet.getCell("I7").value = "02:00";
                worksheet.getCell("J7").value = "02:30";
                worksheet.getCell("K7").value = "03:00";
                worksheet.getCell("L7").value = "03:30";
                worksheet.getCell("M7").value = "04:00";
                worksheet.getCell("N7").value = "04:30";
                worksheet.getCell("O7").value = "05:00";
                worksheet.getCell("P7").value = "05:30";
                worksheet.getCell("Q7").value = "06:00";
                worksheet.getCell("R7").value = "06:30";
                worksheet.getCell("S7").value = "07:00";
                worksheet.getCell("T7").value = "07:30";
                worksheet.getCell("U7").value = "08:00";
                worksheet.getCell("V7").value = "08:30";
                worksheet.getCell("W7").value = "09:00";
                worksheet.getCell("X7").value = "09:30";
                worksheet.getCell("Y7").value = "10:00";
                worksheet.getCell("Z7").value = "10:30";
                worksheet.getCell("AA7").value = "11:00";
                worksheet.getCell("AB7").value = "11:30";
                worksheet.getCell("AC7").value = "12:00";
                worksheet.getCell("AD7").value = "12:30";
                worksheet.getCell("AE7").value = "13:00";
                worksheet.getCell("AF7").value = "13:30";

                worksheet.getCell("AG7").value = "14:00";
                worksheet.getCell("AH7").value = "14:30";
                worksheet.getCell("AI7").value = "15:00";

                worksheet.getCell("AJ7").value = "15:30";
                worksheet.getCell("AK7").value = "16:00";
                worksheet.getCell("AL7").value = "16:30";
                worksheet.getCell("AM7").value = "17:00";
                worksheet.getCell("AN7").value = "17:30";
                worksheet.getCell("AO7").value = "18:00";
                worksheet.getCell("AP7").value = "18:30";
                worksheet.getCell("AQ7").value = "19:00";
                worksheet.getCell("AR7").value = "19:30";
                worksheet.getCell("AS7").value = "20:00";

                worksheet.getCell("AT7").value = "20:30";
                worksheet.getCell("AU7").value = "21:00";
                worksheet.getCell("AV7").value = "21:30";
                worksheet.getCell("AW7").value = "22:00";
                worksheet.getCell("AX7").value = "22:30";
                worksheet.getCell("AY7").value = "23:00";
                worksheet.getCell("AZ7").value = "23:30";

                worksheet.getRow(6).eachCell((cell) =>
                    Object.assign(cell, {
                        border: {
                            top: { style: "thin" },
                            left: { style: "thin" },
                            bottom: { style: "thin" },
                            right: { style: "thin" },
                        },
                    })
                );

                worksheet.getRow(7).eachCell((cell) =>
                    Object.assign(cell, {
                        border: {
                            top: { style: "thin" },
                            left: { style: "thin" },
                            bottom: { style: "thin" },
                            right: { style: "thin" },
                        },
                    })
                );

                // worksheet.cell(2, 2, 5, 5).style(thinBorder);
                // worksheet.cell(2, 2, 2, 5).style({ border: { top: { style: 'thick' } } });
                // worksheet.cell(5, 2, 5, 5).style({ border: { bottom: { style: 'thick' } } });
                // worksheet.cell(2, 2, 5, 2).style({ border: { left: { style: 'thick' } } });
                // worksheet.cell(2, 5, 5, 5).style({ border: { right: { style: 'thick' } } });
                worksheet.columns = [
                    {
                        key: "station_id",
                        width: 7,
                        alignment: "center",
                        wrapText: true,
                        // cell: {
                        //   style: function (event) {
                        //     return (event == YOUR_INPUT ? 'border:1px black dotted; padding-left:5px; padding-right:5px' : '');
                        //   }
                        // }
                    },
                    { key: "station_name", width: 30, wrapText: true },
                    { key: "jam_pengamatan", width: 12, alignment: "center" },
                    { key: "periode", width: 12, alignment: "center" },

                    {
                        key: "00:00",
                        width: 7,
                        bstyle: { border: " 1px solid black" },
                    },
                    { key: "00:30", width: 7 },
                    { key: "01:00", width: 7 },
                    { key: "01:30", width: 7 },
                    { key: "02:00", width: 7 },
                    { key: "02:30", width: 7 },
                    { key: "03:00", width: 7 },
                    { key: "03:30", width: 7 },
                    { key: "04:00", width: 7 },
                    { key: "04:30", width: 7 },
                    { key: "05:00", width: 7 },
                    { key: "05:30", width: 7 },
                    { key: "06:00", width: 7 },
                    { key: "06:30", width: 7 },
                    { key: "07:00", width: 7 },
                    { key: "07:30", width: 7 },
                    { key: "08:00", width: 7 },
                    { key: "08:30", width: 7 },
                    { key: "09:00", width: 7 },
                    { key: "09:30", width: 7 },
                    { key: "10:00", width: 7 },
                    { key: "10:30", width: 7 },
                    { key: "11:00", width: 7 },
                    { key: "11:30", width: 7 },
                    { key: "12:00", width: 7 },
                    { key: "12:30", width: 7 },
                    { key: "13:00", width: 7 },
                    { key: "13:30", width: 7 },
                    { key: "14:00", width: 7 },
                    { key: "14:30", width: 7 },
                    { key: "15:00", width: 7 },
                    { key: "15:30", width: 7 },
                    { key: "16:00", width: 7 },
                    { key: "16:30", width: 7 },
                    { key: "17:00", width: 7 },
                    { key: "17:30", width: 7 },
                    { key: "18:00", width: 7 },
                    { key: "18:30", width: 7 },
                    { key: "19:00", width: 7 },
                    { key: "19:30", width: 7 },
                    { key: "20:00", width: 7 },
                    { key: "20:30", width: 7 },
                    { key: "21:00", width: 7 },
                    { key: "21:30", width: 7 },
                    { key: "22:00", width: 7 },
                    { key: "22:30", width: 7 },
                    { key: "23:00", width: 7 },
                    { key: "23:30", width: 7 },

                    {
                        key: "progress",
                        width: 10,
                        style: { numFmt: '#,#0.00"%"' },
                    },
                    // { key: "DataTidakMasuk", width: 30 },
                ];
                //FOOTER
                worksheet.addRows(this.rows);

                const rowCount = worksheet.rowCount;
                worksheet.getCell(`A${rowCount}`).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                for (let i = 6; i <= rowCount; i++) {
                    const row = worksheet.getRow(7 + i);
                    const data = worksheet.getRow(i);

                    for (let index = 1; index <= 53; index++) {
                        data.getCell(index).border = {
                            top: { style: "thin" },
                            left: { style: "thin" },
                            bottom: { style: "thin" },
                            right: { style: "thin" },
                        };
                    }
                }
                // worksheet.getColumn(rowCount).eachCell(cell => Object.assign(cell, {
                //   border: {
                //     top: { style: 'thin' },
                //     left: { style: 'thin' },
                //     bottom: { style: 'thin' },
                //     right: { style: 'thin' }
                //   }
                // })
                // );
                // worksheet.getRows(`A${8} : AI${rowCount}`).eachCell(cell => Object.assign(cell, {
                //   border: {
                //     top: { style: 'thin' },
                //     left: { style: 'thin' },
                //     bottom: { style: 'thin' },
                //     right: { style: 'thin' }
                //   }
                // })
                // );

                worksheet.mergeCells(`A${rowCount + 4}:D${rowCount + 4}`);
                worksheet.getCell(`A${rowCount + 4}`).value = "Mengetahui.";
                worksheet.getCell(`A${rowCount + 4}`).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.mergeCells(`A${rowCount + 5}:D${rowCount + 5}`);
                worksheet.getCell(`A${rowCount + 5}`).value = "Sub Koordinator";
                worksheet.getCell(`A${rowCount + 5}`).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.mergeCells(`A${rowCount + 6}:D${rowCount + 6}`);
                worksheet.getCell(`A${rowCount + 6}`).value = "Bidang Manajemen Database MKG,";
                worksheet.getCell(`A${rowCount + 6}`).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.mergeCells(`AW${rowCount + 5}:BA${rowCount + 5}`);
                worksheet.getCell(`AW${rowCount + 5}`).value = "Jakarta, " + moment().format("DD MMMM  YYYY");
                worksheet.getCell(`AW${rowCount + 5}`).alignment = {
                    horizontal: "left",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.mergeCells(`AW${rowCount + 6}:AZ${rowCount + 6}`);
                worksheet.getCell(`AW${rowCount + 6}`).value = "Pembuat Laporan, ";

                worksheet.getColumn(1).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.getColumn(3).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.getColumn(4).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
            } else if (this.RESOURCE.LABEL === "PIBAL") {
                const headerRowHasil = worksheet.getRow(1);
                headerRowHasil.height = 30;
                worksheet.mergeCells("A1:I1");
                worksheet.mergeCells("C6:C7");
                worksheet.getCell("C6:C7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.mergeCells("D6:D7");
                worksheet.getCell("D6:D7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };

                const cellPeriode = worksheet.getCell("D6");
                cellPeriode.value = "Periode";

                const cellJamPengamatan = worksheet.getCell("C6");
                // worksheet.getCell("C6").border = {
                //   top: { style: 'thin' },
                //   left: { style: 'thin' },
                //   bottom: { style: 'thin' },
                //   right: { style: 'thin' }
                // };
                cellJamPengamatan.value = "Jam Pengamatan";

                headerRowHasil.getCell(1).value = "Hasil Monitoring Data Bulan" + moment(this.PERIODE).format(" MMMM  YYYY");
                headerRowHasil.getCell(1).font = {
                    name: "Arial Black",
                    size: 16,
                    bold: true,
                    color: "#000",
                };
                headerRowHasil.getCell(1).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };

                const headerRowBalai = worksheet.getRow(3);
                headerRowBalai.height = 30;
                worksheet.mergeCells("A3:I3");

                headerRowBalai.getCell(1).value = "Balai Besar MKG " + this.BALAI.LABEL;

                headerRowBalai.getCell(1).font = {
                    name: "Arial Black",
                    size: 16,
                    verticalAlignment: "center ",
                    bold: true,
                    color: "#000",

                    // color: { argb: "FF0000FF" },
                };
                headerRowBalai.getCell(1).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };

                const headerRowTipe = worksheet.getRow(5);
                headerRowTipe.height = 20;
                worksheet.mergeCells("A5:C5");

                headerRowTipe.getCell(1).value = "Tipe FORM: " + this.RESOURCE.LABEL;
                worksheet.getCell("A5:C5").font = {
                    name: "Arial Black",
                    size: 11,
                    color: "red",
                    verticalAlignment: "left",
                    horizontalAlignment: "left",
                };
                worksheet.getCell("A5:C5").alignment = {
                    horizontal: "left",
                    vertical: "middle",
                    wrapText: true,
                };

                const cellJam = worksheet.getCell("E6");
                worksheet.mergeCells("E6:H6");
                worksheet.getCell("E6:H6").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };

                cellJam.value = "Jam";

                worksheet.getCell("E7").value = "00:00";
                worksheet.getCell("F7").value = "06:00";
                worksheet.getCell("G7").value = "12:00";
                worksheet.getCell("H7").value = "18:00";
                worksheet.mergeCells("I6:I7");

                worksheet.getCell("I7").value = "Progress";
                worksheet.getCell("I7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getColumn(9).numFmt = '#,#0.00"%"';
                worksheet.getRow(6).eachCell((cell) =>
                    Object.assign(cell, {
                        border: {
                            top: { style: "thin" },
                            left: { style: "thin" },
                            bottom: { style: "thin" },
                            right: { style: "thin" },
                        },
                    })
                );

                worksheet.getRow(7).eachCell((cell) =>
                    Object.assign(cell, {
                        border: {
                            top: { style: "thin" },
                            left: { style: "thin" },
                            bottom: { style: "thin" },
                            right: { style: "thin" },
                        },
                    })
                );

                worksheet.columns = [
                    {
                        key: "station_id",
                        width: 7,
                        alignment: "center",
                        wrapText: true,
                    },
                    { key: "station_name", width: 30, wrapText: true },
                    { key: "jam_pengamatan", width: 12, alignment: "center" },
                    { key: "periode", width: 12, alignment: "center" },

                    { key: "00:00", width: 7 },

                    { key: "06:00", width: 7 },

                    { key: "12:00", width: 7 },

                    { key: "18:00", width: 7 },

                    {
                        key: "progress",
                        width: 10,
                        style: { numFmt: '#,#0.00"%"' },
                    },
                ];
                // worksheet.getCell(`A8:I${rowCount + 1}`).forEach(cell => {
                //   cell.border = {
                //     top: { style: "thin" },
                //     left: { style: "thin" },
                //     bottom: { style: "thin" },
                //     right: { style: "thin" }
                //   };
                // });

                //FOOTER
                worksheet.addRows(this.rows);

                const rowCount = worksheet.rowCount;
                const rowHeader = worksheet.getRow(8);

                // for (let i = 1; i <= 9; i++) {
                //   // Untuk border table
                //   rowHeader.getCell(i).border = {
                //     top: { style: 'thin' },
                //     left: { style: 'thin' },
                //     bottom: { style: 'thin' },
                //     right: { style: 'thin' }
                //   }
                // }

                for (let i = 6; i <= rowCount; i++) {
                    const row = worksheet.getRow(7 + i);
                    const data = worksheet.getRow(i);

                    for (let index = 1; index <= 9; index++) {
                        data.getCell(index).border = {
                            top: { style: "thin" },
                            left: { style: "thin" },
                            bottom: { style: "thin" },
                            right: { style: "thin" },
                        };
                    }
                }

                // worksheet.rowCount.getCell(`A8:I${rowCount + 1}`).border = {
                //   top: { style: 'thin' },
                //   left: { style: 'thin' },
                //   bottom: { style: 'thin' },
                //   right: { style: 'thin' }
                // };
                worksheet.getCell(`A${rowCount + 1}:H${rowCount + 1}`).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.getCell(`A${rowCount + 1}`).font = { bold: true };
                // worksheet.mergeCells(`A${rowCount + 1}:H${rowCount + 1}`);
                // worksheet.getCell(`A${rowCount + 1}:H${rowCount + 1}`).value = "TOTAL";
                // worksheet.getCell(`A${rowCount}:H${rowCount}`).alignment = {
                //   horizontal: "center",
                //   vertical: "middle",
                // };

                worksheet.getCell(`A${rowCount}`).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.mergeCells(`A${rowCount + 4}:B${rowCount + 4}`);
                worksheet.getCell(`A${rowCount + 4}`).value = "Mengetahui.";

                worksheet.getCell(`A${rowCount + 4}`).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.mergeCells(`A${rowCount + 5}:B${rowCount + 5}`);
                worksheet.getCell(`A${rowCount + 5}`).value = "Sub Koordinator";
                worksheet.getCell(`A${rowCount + 5}`).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.mergeCells(`A${rowCount + 6}:B${rowCount + 6}`);
                worksheet.getCell(`A${rowCount + 6}`).value = "Bidang Manajemen Database MKG,";
                worksheet.getCell(`A${rowCount + 6}`).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.mergeCells(`F${rowCount + 5}:I${rowCount + 5}`);
                worksheet.getCell(`F${rowCount + 5}`).value = "Jakarta, " + moment().format("DD MMMM  YYYY");
                worksheet.getCell(`F${rowCount + 5}`).alignment = {
                    horizontal: "left",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.mergeCells(`F${rowCount + 6}:H${rowCount + 6}`);
                worksheet.getCell(`F${rowCount + 6}`).value = "Pembuat Laporan, ";

                worksheet.getColumn(1).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.getColumn(3).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.getColumn(4).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
            } else if (this.RESOURCE.LABEL === "SINOPTIK") {
                const headerRowHasil = worksheet.getRow(1);
                headerRowHasil.height = 30;
                worksheet.mergeCells("A1:AC1");
                worksheet.mergeCells("C6:C7");
                worksheet.getCell("C6:C7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                // worksheet.mergeCells("D6:D7");
                // worksheet.getCell("D6:D7").alignment = {
                //     horizontal: "center",
                //     vertical: "middle",
                // };

                const cellPeriode = worksheet.getCell("C6");
                cellPeriode.value = "Periode";

                // const cellJamPengamatan = worksheet.getCell("C6");
                // cellJamPengamatan.value = "Jam Pengamatan";

                headerRowHasil.getCell(1).value = "Hasil Monitoring GTS" + moment(this.PERIODE).format(" MMMM  YYYY");
                headerRowHasil.getCell(1).font = {
                    name: "Arial Black",
                    size: 16,
                    bold: true,
                    color: "#000",
                };
                headerRowHasil.getCell(1).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };

                const headerRowBalai = worksheet.getRow(3);
                headerRowBalai.height = 30;
                worksheet.mergeCells("A3:AC3");

                headerRowBalai.getCell(1).value = "Balai Besar MKG " + this.BALAI.LABEL;

                headerRowBalai.getCell(1).font = {
                    name: "Arial Black",
                    size: 16,
                    verticalAlignment: "center ",
                    bold: true,
                    color: "#000",

                    // color: { argb: "FF0000FF" },
                };
                headerRowBalai.getCell(1).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };

                const headerRowTipe = worksheet.getRow(5);
                headerRowTipe.height = 20;
                worksheet.mergeCells("A5:C5");

                headerRowTipe.getCell(1).value = "Tipe FORM: " + this.RESOURCE.LABEL;
                worksheet.getCell("A5:C5").font = {
                    name: "Arial Black",
                    size: 11,
                    color: "red",
                    verticalAlignment: "left",
                    horizontalAlignment: "left",
                };
                worksheet.getCell("A5:C5").alignment = {
                    horizontal: "left",
                    vertical: "middle",
                    wrapText: true,
                };

                const cellJam = worksheet.getCell("D6");
                worksheet.mergeCells("D6:AA6");
                worksheet.getCell("D6:AA6").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };

                cellJam.value = "Jam";

                worksheet.getCell("D7").value = "00:00";
                worksheet.getCell("E7").value = "01:00";
                worksheet.getCell("F7").value = "02:00";
                worksheet.getCell("G7").value = "03:00";
                worksheet.getCell("H7").value = "04:00";
                worksheet.getCell("I7").value = "05:00";
                worksheet.getCell("J7").value = "06:00";
                worksheet.getCell("K7").value = "07:00";
                worksheet.getCell("L7").value = "08:00";
                worksheet.getCell("M7").value = "09:00";
                worksheet.getCell("N7").value = "10:00";
                worksheet.getCell("O7").value = "11:00";
                worksheet.getCell("P7").value = "12:00";
                worksheet.getCell("Q7").value = "13:00";

                worksheet.getCell("R7").value = "14:00";
                worksheet.getCell("S7").value = "15:00";
                worksheet.getCell("T7").value = "16:00";
                worksheet.getCell("U7").value = "17:00";
                worksheet.getCell("V7").value = "18:00";
                worksheet.getCell("W7").value = "19:00";
                worksheet.getCell("X7").value = "20:00";

                worksheet.getCell("Y7").value = "21:00";
                worksheet.getCell("Z7").value = "22:00";
                worksheet.getCell("AA7").value = "23:00";

                // worksheet.mergeCells("AC6:AC7");

                // worksheet.getCell("AC7").value = "Progress";
                // worksheet.getCell("AC7").alignment = {
                //     horizontal: "center",
                //     vertical: "middle",
                // };
                // worksheet.getColumn(29).numFmt = '#,#0.00"%"';
                worksheet.getRow(6).eachCell((cell) =>
                    Object.assign(cell, {
                        border: {
                            top: { style: "thin" },
                            left: { style: "thin" },
                            bottom: { style: "thin" },
                            right: { style: "thin" },
                        },
                    })
                );

                worksheet.getRow(7).eachCell((cell) =>
                    Object.assign(cell, {
                        border: {
                            top: { style: "thin" },
                            left: { style: "thin" },
                            bottom: { style: "thin" },
                            right: { style: "thin" },
                        },
                    })
                );

                worksheet.columns = [
                    {
                        key: "station_id",
                        width: 7,
                        alignment: "center",
                        wrapText: true,
                    },
                    { key: "station_name", width: 50, wrapText: true },
                    // { key: "jam_pengamatan", width: 12, alignment: "center" },
                    { key: "periode", width: 12, alignment: "center" },

                    { key: "00:00", width: 7 },
                    { key: "01:00", width: 7 },
                    { key: "02:00", width: 7 },
                    { key: "03:00", width: 7 },
                    { key: "04:00", width: 7 },
                    { key: "05:00", width: 7 },
                    { key: "06:00", width: 7 },
                    { key: "07:00", width: 7 },
                    { key: "08:00", width: 7 },
                    { key: "09:00", width: 7 },
                    { key: "10:00", width: 7 },
                    { key: "11:00", width: 7 },
                    { key: "12:00", width: 7 },
                    { key: "13:00", width: 7 },
                    { key: "14:00", width: 7 },
                    { key: "15:00", width: 7 },
                    { key: "16:00", width: 7 },
                    { key: "17:00", width: 7 },
                    { key: "18:00", width: 7 },
                    { key: "19:00", width: 7 },
                    { key: "20:00", width: 7 },
                    { key: "21:00", width: 7 },
                    { key: "22:00", width: 7 },
                    { key: "23:00", width: 7 },
                    // {
                    //     key: "progress",
                    //     width: 10,
                    //     style: { numFmt: '#,#0.00"%"' },
                    // },
                ];
                //FOOTER
                // worksheet.addRows(this.rows);
                console.log("this.rowsXls", this.rowsXls);
                worksheet.addRows(this.rowsXls);

                const rowCount = worksheet.rowCount;
                for (let i = 6; i <= rowCount; i++) {
                    const row = worksheet.getRow(7 + i);
                    const data = worksheet.getRow(i);

                    for (let index = 1; index <= 27; index++) {
                        data.getCell(index).border = {
                            top: { style: "thin" },
                            left: { style: "thin" },
                            bottom: { style: "thin" },
                            right: { style: "thin" },
                        };
                    }
                }
                worksheet.getCell(`A${rowCount + 1}:H${rowCount + 1}`).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.getCell(`A${rowCount + 1}`).font = { bold: true };
                // worksheet.mergeCells(`A${rowCount + 1}:AB${rowCount + 1}`);
                // worksheet.getCell(`A${rowCount + 1}:AB${rowCount + 1}`).value = "TOTAL";
                // worksheet.getCell(`A${rowCount}:AB${rowCount}`).alignment = {
                //   horizontal: "center",
                //   vertical: "middle",
                // };
                worksheet.getCell(`A${rowCount}`).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.mergeCells(`A${rowCount + 4}:B${rowCount + 4}`);
                worksheet.getCell(`A${rowCount + 4}`).value = "Mengetahui.";
                worksheet.getCell(`A${rowCount + 4}`).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.mergeCells(`A${rowCount + 5}:B${rowCount + 5}`);

                worksheet.getCell(`A${rowCount + 5}`).value = "Sub Koordinator";
                worksheet.getCell(`A${rowCount + 5}`).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.mergeCells(`A${rowCount + 6}:B${rowCount + 6}`);
                worksheet.getCell(`A${rowCount + 6}`).value = "Bidang Manajemen Database MKG,";
                worksheet.getCell(`A${rowCount + 6}`).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.mergeCells(`Z${rowCount + 5}:AC${rowCount + 5}`);
                worksheet.getCell(`Z${rowCount + 5}`).value = "Jakarta, " + moment().format("DD MMMM  YYYY");
                worksheet.getCell(`Z${rowCount + 5}`).alignment = {
                    horizontal: "left",
                    vertical: "middle",
                    wrapText: true,
                };

                worksheet.getCell(`Z${rowCount + 5}`).alignment = {
                    horizontal: "left",
                    vertical: "left",
                    wrapText: true,
                };
                worksheet.mergeCells(`Z${rowCount + 6}:AB${rowCount + 6}`);
                worksheet.getCell(`Z${rowCount + 6}`).value = "Pembuat Laporan, ";

                worksheet.getColumn(1).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.getColumn(3).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.getColumn(4).alignment = {
                    horizontal: "center",
                    vertical: "middle",
                    wrapText: true,
                };
            } else {
                var tahun_kabisat = this.PERIODE.substring(0, 4);

                if (
                    this.PERIODE.substring(5, 12) == "01" ||
                    this.PERIODE.substring(5, 12) == "03" ||
                    this.PERIODE.substring(5, 12) == "05" ||
                    this.PERIODE.substring(5, 12) == "07" ||
                    this.PERIODE.substring(5, 12) == "08" ||
                    this.PERIODE.substring(5, 12) == "10" ||
                    this.PERIODE.substring(5, 12) == "12"
                ) {
                    const headerRowHasil = worksheet.getRow(1);
                    headerRowHasil.height = 30;
                    worksheet.mergeCells("A1:AI1");
                    worksheet.mergeCells("C6:C7");
                    worksheet.getCell("C6:C7").alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };
                    headerRowHasil.getCell(1).value = "Hasil Monitoring Data Bulan" + moment(this.PERIODE).format(" MMMM  YYYY");
                    headerRowHasil.getCell(1).font = {
                        name: "Arial Black",
                        size: 16,
                        bold: true,
                        color: "#000",
                    };
                    headerRowHasil.getCell(1).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };

                    const headerRowBalai = worksheet.getRow(3);
                    headerRowBalai.height = 30;
                    worksheet.mergeCells("A3:AI3");

                    headerRowBalai.getCell(1).value = "Balai Besar MKG " + this.BALAI.LABEL;

                    headerRowBalai.getCell(1).font = {
                        name: "Arial Black",
                        size: 16,
                        verticalAlignment: "center ",
                        bold: true,
                        color: "#000",
                    };
                    headerRowBalai.getCell(1).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };

                    worksheet.columns = [
                        {
                            key: "station_id",
                            width: 7,
                            alignment: "center",
                            wrapText: true,
                        },
                        { key: "station_name", width: 30, wrapText: true },
                        { key: "periode", width: 10, alignment: "center" },

                        { key: "01", width: 7 },
                        { key: "02", width: 7 },
                        { key: "03", width: 7 },
                        { key: "04", width: 7 },
                        { key: "05", width: 7 },
                        { key: "06", width: 7 },
                        { key: "07", width: 7 },
                        { key: "08", width: 7 },
                        { key: "09", width: 7 },
                        { key: "10", width: 7 },
                        { key: "11", width: 7 },
                        { key: "12", width: 7 },
                        { key: "13", width: 7 },
                        { key: "14", width: 7 },
                        { key: "15", width: 7 },
                        { key: "16", width: 7 },
                        { key: "17", width: 7 },
                        { key: "18", width: 7 },
                        { key: "19", width: 7 },
                        { key: "20", width: 7 },
                        { key: "21", width: 7 },
                        { key: "22", width: 7 },
                        { key: "23", width: 7 },
                        { key: "24", width: 7 },
                        { key: "25", width: 7 },
                        { key: "26", width: 7 },
                        { key: "27", width: 7 },
                        { key: "28", width: 7 },
                        { key: "29", width: 7 },
                        { key: "30", width: 7 },
                        { key: "31", width: 7 },
                        {
                            key: "progress",
                            width: 10,
                            style: { numFmt: '#,#0.00"%"' },
                        },
                    ];

                    const cellTanggal = worksheet.getCell("D6");
                    worksheet.mergeCells("D6:AH6");
                    worksheet.getCell("D6:AH6").alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };

                    cellTanggal.value = "Tanggal";
                    worksheet.getRow(6).eachCell((cell) =>
                        Object.assign(cell, {
                            border: {
                                top: { style: "thin" },
                                left: { style: "thin" },
                                bottom: { style: "thin" },
                                right: { style: "thin" },
                            },
                        })
                    );

                    worksheet.getRow(7).eachCell((cell) =>
                        Object.assign(cell, {
                            border: {
                                top: { style: "thin" },
                                left: { style: "thin" },
                                bottom: { style: "thin" },
                                right: { style: "thin" },
                            },
                        })
                    );
                    worksheet.mergeCells("AI6:AI7");

                    worksheet.getCell("AI6:AI7").alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };

                    worksheet.getCell("AI6").value = "Progress";

                    const cellTanggal29 = worksheet.getCell("AF7");
                    worksheet.getCell("AF7").alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };
                    const cellTanggal30 = worksheet.getCell("AG7");
                    worksheet.getCell("AG7").alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };

                    const cellTanggal31 = worksheet.getCell("AH7");
                    worksheet.getCell("AH7").alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };

                    cellTanggal29.value = "29";
                    cellTanggal30.value = "30";
                    cellTanggal31.value = "31";

                    worksheet.addRows(this.rows);
                    const rowCount = worksheet.rowCount;
                    for (let i = 6; i <= rowCount; i++) {
                        const row = worksheet.getRow(7 + i);
                        const data = worksheet.getRow(i);

                        for (let index = 1; index <= 35; index++) {
                            data.getCell(index).border = {
                                top: { style: "thin" },
                                left: { style: "thin" },
                                bottom: { style: "thin" },
                                right: { style: "thin" },
                            };
                        }
                    }
                    worksheet.getCell(`A${rowCount + 1}:H${rowCount + 1}`).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };

                    // worksheet.getCell(`A${rowCount}:AI${rowCount}`).border = {
                    //   top: { style: 'thin' },
                    //   left: { style: 'thin' },
                    //   bottom: { style: 'thin' },
                    //   right: { style: 'thin' }
                    // };

                    worksheet.getCell(`A${rowCount}`).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };
                    worksheet.mergeCells(`A${rowCount + 4}:B${rowCount + 4}`);
                    worksheet.getCell(`A${rowCount + 4}`).value = "Mengetahui.";
                    worksheet.getCell(`A${rowCount + 4}`).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };
                    worksheet.mergeCells(`A${rowCount + 5}:B${rowCount + 5}`);
                    worksheet.getCell(`A${rowCount + 5}`).value = "Sub Koordinator";
                    worksheet.getCell(`A${rowCount + 5}`).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };
                    worksheet.mergeCells(`A${rowCount + 6}:B${rowCount + 6}`);
                    worksheet.getCell(`A${rowCount + 6}`).value = "Bidang Manajemen Database MKG,";
                    worksheet.getCell(`A${rowCount + 6}`).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };

                    worksheet.getColumn(1).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };
                    worksheet.getColumn(3).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };
                    worksheet.getColumn(4).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };

                    worksheet.mergeCells(`AF${rowCount + 5}:AI${rowCount + 5}`);
                    worksheet.getCell(`AF${rowCount + 5}`).value = "Jakarta, " + moment().format("DD MMMM  YYYY");
                    worksheet.getCell(`AF${rowCount + 5}`).alignment = {
                        horizontal: "left",
                        vertical: "middle",
                        wrapText: true,
                    };

                    worksheet.mergeCells(`AF${rowCount + 6}:AI${rowCount + 6}`);
                    worksheet.getCell(`AF${rowCount + 6}`).value = "Pembuat Laporan, ";
                    worksheet.getCell(`AF${rowCount + 6}`).alignment = {
                        horizontal: "left",
                        vertical: "middle",
                        wrapText: true,
                    };
                } else if (this.PERIODE.substring(5, 12) == "04" || this.PERIODE.substring(5, 12) == "06" || this.PERIODE.substring(5, 12) == "09" || this.PERIODE.substring(5, 12) == "11") {
                    const headerRowHasil = worksheet.getRow(1);
                    headerRowHasil.height = 30;
                    worksheet.mergeCells("A1:AH1");
                    worksheet.mergeCells("C6:C7");
                    worksheet.getCell("C6:C7").alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };
                    headerRowHasil.getCell(1).value = "Hasil Monitoring Data Bulan" + moment(this.PERIODE).format(" MMMM  YYYY");
                    headerRowHasil.getCell(1).font = {
                        name: "Arial Black",
                        size: 16,
                        bold: true,
                        color: "#000",
                    };
                    headerRowHasil.getCell(1).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };

                    const headerRowBalai = worksheet.getRow(3);
                    headerRowBalai.height = 30;
                    worksheet.mergeCells("A3:AH3");

                    headerRowBalai.getCell(1).value = "Balai Besar MKG " + this.BALAI.LABEL;

                    headerRowBalai.getCell(1).font = {
                        name: "Arial Black",
                        size: 16,
                        verticalAlignment: "center ",
                        bold: true,
                        color: "#000",
                    };
                    headerRowBalai.getCell(1).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };

                    worksheet.columns = [
                        { key: "station_id", width: 7, alignment: "center" },
                        { key: "station_name", width: 30, wrapText: true },
                        { key: "periode", width: 10, alignment: "center" },

                        { key: "01", width: 7 },
                        { key: "02", width: 7 },
                        { key: "03", width: 7 },
                        { key: "04", width: 7 },
                        { key: "05", width: 7 },
                        { key: "06", width: 7 },
                        { key: "07", width: 7 },
                        { key: "08", width: 7 },
                        { key: "09", width: 7 },
                        { key: "10", width: 7 },
                        { key: "11", width: 7 },
                        { key: "12", width: 7 },
                        { key: "13", width: 7 },
                        { key: "14", width: 7 },
                        { key: "15", width: 7 },
                        { key: "16", width: 7 },
                        { key: "17", width: 7 },
                        { key: "18", width: 7 },
                        { key: "19", width: 7 },
                        { key: "20", width: 7 },
                        { key: "21", width: 7 },
                        { key: "22", width: 7 },
                        { key: "23", width: 7 },
                        { key: "24", width: 7 },
                        { key: "25", width: 7 },
                        { key: "26", width: 7 },
                        { key: "27", width: 7 },
                        { key: "28", width: 7 },
                        { key: "29", width: 7 },
                        { key: "30", width: 7 },
                        {
                            key: "progress",
                            width: 10,
                            style: { numFmt: '#,#0.00"%"' },
                        },
                    ];
                    const cellTanggal = worksheet.getCell("D6");
                    worksheet.mergeCells("D6:AG6");
                    worksheet.getCell("D6:AG6").alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };

                    cellTanggal.value = "Tanggal";
                    worksheet.getRow(6).eachCell((cell) =>
                        Object.assign(cell, {
                            border: {
                                top: { style: "thin" },
                                left: { style: "thin" },
                                bottom: { style: "thin" },
                                right: { style: "thin" },
                            },
                        })
                    );

                    worksheet.getRow(7).eachCell((cell) =>
                        Object.assign(cell, {
                            border: {
                                top: { style: "thin" },
                                left: { style: "thin" },
                                bottom: { style: "thin" },
                                right: { style: "thin" },
                            },
                        })
                    );
                    worksheet.mergeCells("AH6:AH7");
                    worksheet.getCell("AH6:AH7").alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };

                    worksheet.getCell("AH6").value = "Progress";

                    const cellTanggal29 = worksheet.getCell("AF7");
                    worksheet.getCell("AF7").alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };

                    const cellTanggal30 = worksheet.getCell("AG7");
                    worksheet.getCell("AG7").alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };

                    cellTanggal29.value = "29";
                    cellTanggal30.value = "30";

                    worksheet.addRows(this.rows);
                    const rowCount = worksheet.rowCount;
                    for (let i = 6; i <= rowCount; i++) {
                        const row = worksheet.getRow(7 + i);
                        const data = worksheet.getRow(i);

                        for (let index = 1; index <= 34; index++) {
                            data.getCell(index).border = {
                                top: { style: "thin" },
                                left: { style: "thin" },
                                bottom: { style: "thin" },
                                right: { style: "thin" },
                            };
                        }
                    }
                    worksheet.getCell(`A${rowCount + 1}:H${rowCount + 1}`).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };

                    worksheet.getCell(`A${rowCount}`).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };
                    worksheet.mergeCells(`A${rowCount + 4}:B${rowCount + 4}`);
                    worksheet.getCell(`A${rowCount + 4}`).value = "Mengetahui.";
                    worksheet.getCell(`A${rowCount + 4}`).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };
                    worksheet.mergeCells(`A${rowCount + 5}:B${rowCount + 5}`);
                    worksheet.getCell(`A${rowCount + 5}`).value = "Sub Koordinator";
                    worksheet.getCell(`A${rowCount + 5}`).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };
                    worksheet.mergeCells(`A${rowCount + 6}:B${rowCount + 6}`);
                    worksheet.getCell(`A${rowCount + 6}`).value = "Bidang Manajemen Database MKG,";
                    worksheet.getCell(`A${rowCount + 6}`).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };

                    worksheet.getColumn(1).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };
                    worksheet.getColumn(3).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };
                    worksheet.getColumn(4).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };

                    worksheet.mergeCells(`AE${rowCount + 5}:AH${rowCount + 5}`);
                    worksheet.getCell(`AE${rowCount + 5}`).value = "Jakarta, " + moment().format("DD MMMM  YYYY");
                    worksheet.getCell(`AE${rowCount + 5}`).alignment = {
                        horizontal: "left",
                        vertical: "middle",
                        wrapText: true,
                    };

                    worksheet.mergeCells(`AE${rowCount + 6}:AH${rowCount + 6}`);
                    worksheet.getCell(`AE${rowCount + 6}`).value = "Pembuat Laporan, ";
                    worksheet.getCell(`AE${rowCount + 6}`).alignment = {
                        horizontal: "left",
                        vertical: "middle",
                        wrapText: true,
                    };
                } else if ((this.PERIODE.substring(5, 12) == "02" && 0 == tahun_kabisat % 4 && 0 != tahun_kabisat % 100) || 0 == tahun_kabisat % 400) {
                    const headerRowHasil = worksheet.getRow(1);
                    headerRowHasil.height = 30;
                    worksheet.mergeCells("A1:AG1");
                    worksheet.mergeCells("C6:C7");
                    worksheet.getCell("C6:C7").alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };
                    headerRowHasil.getCell(1).value = "Hasil Monitoring Data Bulan" + moment(this.PERIODE).format(" MMMM  YYYY");
                    headerRowHasil.getCell(1).font = {
                        name: "Arial Black",
                        size: 16,
                        bold: true,
                        color: "#000",
                    };
                    headerRowHasil.getCell(1).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };

                    const headerRowBalai = worksheet.getRow(3);
                    headerRowBalai.height = 30;
                    worksheet.mergeCells("A3:AG3");

                    headerRowBalai.getCell(1).value = "Balai Besar MKG " + this.BALAI.LABEL;

                    headerRowBalai.getCell(1).font = {
                        name: "Arial Black",
                        size: 16,
                        verticalAlignment: "center ",
                        bold: true,
                        color: "#000",
                    };
                    headerRowBalai.getCell(1).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };

                    worksheet.columns = [
                        {
                            key: "station_id",
                            width: 7,
                            alignment: "center",
                            wrapText: true,
                        },
                        { key: "station_name", width: 30, wrapText: true },
                        { key: "periode", width: 10, alignment: "center" },

                        { key: "01", width: 7 },
                        { key: "02", width: 7 },
                        { key: "03", width: 7 },
                        { key: "04", width: 7 },
                        { key: "05", width: 7 },
                        { key: "06", width: 7 },
                        { key: "07", width: 7 },
                        { key: "08", width: 7 },
                        { key: "09", width: 7 },
                        { key: "10", width: 7 },
                        { key: "11", width: 7 },
                        { key: "12", width: 7 },
                        { key: "13", width: 7 },
                        { key: "14", width: 7 },
                        { key: "15", width: 7 },
                        { key: "16", width: 7 },
                        { key: "17", width: 7 },
                        { key: "18", width: 7 },
                        { key: "19", width: 7 },
                        { key: "20", width: 7 },
                        { key: "21", width: 7 },
                        { key: "22", width: 7 },
                        { key: "23", width: 7 },
                        { key: "24", width: 7 },
                        { key: "25", width: 7 },
                        { key: "26", width: 7 },
                        { key: "27", width: 7 },
                        { key: "28", width: 7 },
                        { key: "29", width: 7 },
                        {
                            key: "progress",
                            width: 10,
                            style: { numFmt: '#,#0.00"%"' },
                        },
                    ];

                    worksheet.addRows(this.rows);
                    const rowCount = worksheet.rowCount;
                    for (let i = 6; i <= rowCount; i++) {
                        const row = worksheet.getRow(7 + i);
                        const data = worksheet.getRow(i);

                        for (let index = 1; index <= 33; index++) {
                            data.getCell(index).border = {
                                top: { style: "thin" },
                                left: { style: "thin" },
                                bottom: { style: "thin" },
                                right: { style: "thin" },
                            };
                        }
                    }
                    worksheet.getCell(`A${rowCount + 1}:H${rowCount + 1}`).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };

                    worksheet.getCell(`A${rowCount}`).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };
                    worksheet.mergeCells(`A${rowCount + 4}:B${rowCount + 4}`);
                    worksheet.getCell(`A${rowCount + 4}`).value = "Mengetahui.";
                    worksheet.getCell(`A${rowCount + 4}`).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };
                    worksheet.mergeCells(`A${rowCount + 5}:B${rowCount + 5}`);
                    worksheet.getCell(`A${rowCount + 5}`).value = "Sub Koordinator";
                    worksheet.getCell(`A${rowCount + 5}`).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };
                    worksheet.mergeCells(`A${rowCount + 6}:B${rowCount + 6}`);
                    worksheet.getCell(`A${rowCount + 6}`).value = "Bidang Manajemen Database MKG,";
                    worksheet.getCell(`A${rowCount + 6}`).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };

                    worksheet.getColumn(1).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };
                    worksheet.getColumn(3).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };
                    worksheet.getColumn(4).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };

                    worksheet.mergeCells(`AD${rowCount + 5}:AG${rowCount + 5}`);
                    worksheet.getCell(`AD${rowCount + 5}`).value = "Jakarta, " + moment().format("DD MMMM  YYYY");
                    worksheet.getCell(`AD${rowCount + 5}`).alignment = {
                        horizontal: "left",
                        vertical: "middle",
                        wrapText: true,
                    };

                    worksheet.mergeCells(`AD${rowCount + 6}:AG${rowCount + 6}`);
                    worksheet.getCell(`AD${rowCount + 6}`).value = "Pembuat Laporan, ";
                    worksheet.getCell(`AD${rowCount + 6}`).alignment = {
                        horizontal: "left",
                        vertical: "middle",
                        wrapText: true,
                    };

                    const cellTanggal = worksheet.getCell("D6");
                    worksheet.mergeCells("D6:AF6");
                    worksheet.getCell("D6:AF6").alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };
                    cellTanggal.value = "Tanggal";
                    worksheet.getRow(6).eachCell((cell) =>
                        Object.assign(cell, {
                            border: {
                                top: { style: "thin" },
                                left: { style: "thin" },
                                bottom: { style: "thin" },
                                right: { style: "thin" },
                            },
                        })
                    );

                    worksheet.getRow(7).eachCell((cell) =>
                        Object.assign(cell, {
                            border: {
                                top: { style: "thin" },
                                left: { style: "thin" },
                                bottom: { style: "thin" },
                                right: { style: "thin" },
                            },
                        })
                    );
                    worksheet.mergeCells("AG6:AG7");
                    worksheet.getCell("AG6:AG7").alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };

                    worksheet.getCell("AG6").value = "Progress";
                    const cellTanggal29 = worksheet.getCell("AF7");
                    worksheet.getCell("AF7").alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };
                    cellTanggal29.value = "29";
                } else {
                    const headerRowHasil = worksheet.getRow(1);
                    headerRowHasil.height = 30;
                    worksheet.mergeCells("A1:AF1");
                    worksheet.mergeCells("C6:C7");
                    worksheet.getCell("C6:C7").alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };
                    headerRowHasil.getCell(1).value = "Hasil Monitoring Data Bulan" + moment(this.PERIODE).format(" MMMM  YYYY");
                    headerRowHasil.getCell(1).font = {
                        name: "Arial Black",
                        size: 16,
                        bold: true,
                        color: "#000",
                    };
                    headerRowHasil.getCell(1).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };

                    const headerRowBalai = worksheet.getRow(3);
                    headerRowBalai.height = 30;
                    worksheet.mergeCells("A3:AF3");

                    headerRowBalai.getCell(1).value = "Balai Besar MKG " + this.BALAI.LABEL;

                    headerRowBalai.getCell(1).font = {
                        name: "Arial Black",
                        size: 16,
                        verticalAlignment: "center ",
                        bold: true,
                        color: "#000",
                    };
                    headerRowBalai.getCell(1).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };

                    worksheet.columns = [
                        {
                            key: "station_id",
                            width: 7,
                            alignment: "center",
                            wrapText: true,
                        },
                        { key: "station_name", width: 30, wrapText: true },
                        { key: "periode", width: 10, alignment: "center" },

                        { key: "01", width: 7 },
                        { key: "02", width: 7 },
                        { key: "03", width: 7 },
                        { key: "04", width: 7 },
                        { key: "05", width: 7 },
                        { key: "06", width: 7 },
                        { key: "07", width: 7 },
                        { key: "08", width: 7 },
                        { key: "09", width: 7 },
                        { key: "10", width: 7 },
                        { key: "11", width: 7 },
                        { key: "12", width: 7 },
                        { key: "13", width: 7 },
                        { key: "14", width: 7 },
                        { key: "15", width: 7 },
                        { key: "16", width: 7 },
                        { key: "17", width: 7 },
                        { key: "18", width: 7 },
                        { key: "19", width: 7 },
                        { key: "20", width: 7 },
                        { key: "21", width: 7 },
                        { key: "22", width: 7 },
                        { key: "23", width: 7 },
                        { key: "24", width: 7 },
                        { key: "25", width: 7 },
                        { key: "26", width: 7 },
                        { key: "27", width: 7 },
                        { key: "28", width: 7 },
                        {
                            key: "progress",
                            width: 10,
                            style: { numFmt: '#,#0.00"%"' },
                        },
                    ];
                    const cellTanggal = worksheet.getCell("D6");
                    worksheet.mergeCells("D6:AE6");
                    worksheet.getCell("D6:AE6").alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };

                    cellTanggal.value = "Tanggal";

                    worksheet.mergeCells("AF6:AF7");
                    worksheet.getCell("AF6:AF7").alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };

                    worksheet.getCell("AF6").value = "Progress";

                    worksheet.addRows(this.rows);
                    const rowCount = worksheet.rowCount;
                    for (let i = 6; i <= rowCount; i++) {
                        const row = worksheet.getRow(7 + i);
                        const data = worksheet.getRow(i);

                        for (let index = 1; index <= 32; index++) {
                            data.getCell(index).border = {
                                top: { style: "thin" },
                                left: { style: "thin" },
                                bottom: { style: "thin" },
                                right: { style: "thin" },
                            };
                        }
                    }
                    worksheet.getCell(`A${rowCount + 1}:H${rowCount + 1}`).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };

                    worksheet.getCell(`A${rowCount}`).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };
                    worksheet.mergeCells(`A${rowCount + 4}:B${rowCount + 4}`);
                    worksheet.getCell(`A${rowCount + 4}`).value = "Mengetahui.";
                    worksheet.getCell(`A${rowCount + 4}`).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };
                    worksheet.mergeCells(`A${rowCount + 5}:B${rowCount + 5}`);
                    worksheet.getCell(`A${rowCount + 5}`).value = "Sub Koordinator";
                    worksheet.getCell(`A${rowCount + 5}`).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };
                    worksheet.mergeCells(`A${rowCount + 6}:B${rowCount + 6}`);
                    worksheet.getCell(`A${rowCount + 6}`).value = "Bidang Manajemen Database MKG,";
                    worksheet.getCell(`A${rowCount + 6}`).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };

                    worksheet.getColumn(1).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };
                    worksheet.getColumn(3).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };
                    worksheet.getColumn(4).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                        wrapText: true,
                    };

                    worksheet.mergeCells(`AC${rowCount + 5}:AF${rowCount + 5}`);
                    worksheet.getCell(`AC${rowCount + 5}`).value = "Jakarta, " + moment().format("DD MMMM  YYYY");
                    worksheet.getCell(`AC${rowCount + 5}`).alignment = {
                        horizontal: "left",
                        vertical: "middle",
                        wrapText: true,
                    };

                    worksheet.mergeCells(`AC${rowCount + 6}:AF${rowCount + 6}`);
                    worksheet.getCell(`AC${rowCount + 6}`).value = "Pembuat Laporan, ";
                    worksheet.getCell(`AC${rowCount + 6}`).alignment = {
                        horizontal: "left",
                        vertical: "middle",
                        wrapText: true,
                    };
                    worksheet.getRow(6).eachCell((cell) =>
                        Object.assign(cell, {
                            border: {
                                top: { style: "thin" },
                                left: { style: "thin" },
                                bottom: { style: "thin" },
                                right: { style: "thin" },
                            },
                        })
                    );

                    worksheet.getRow(7).eachCell((cell) =>
                        Object.assign(cell, {
                            border: {
                                top: { style: "thin" },
                                left: { style: "thin" },
                                bottom: { style: "thin" },
                                right: { style: "thin" },
                            },
                        })
                    );
                }

                const cellPeriode = worksheet.getCell("C6");

                cellPeriode.value = "Periode";

                const cellTanggal1 = worksheet.getCell("D7");
                worksheet.getCell("D7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("D7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
                const cellTanggal2 = worksheet.getCell("E7");
                worksheet.getCell("E7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("E7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal3 = worksheet.getCell("F7");
                worksheet.getCell("F7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("F7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal4 = worksheet.getCell("G7");
                worksheet.getCell("G7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("G7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal5 = worksheet.getCell("H7");
                worksheet.getCell("H7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("H7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal6 = worksheet.getCell("I7");
                worksheet.getCell("I7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("I7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal7 = worksheet.getCell("J7");
                worksheet.getCell("J7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("J7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal8 = worksheet.getCell("K7");
                worksheet.getCell("K7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("K7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal9 = worksheet.getCell("L7");
                worksheet.getCell("L7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("L7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal10 = worksheet.getCell("M7");
                worksheet.getCell("M7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("M7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal11 = worksheet.getCell("N7");
                worksheet.getCell("N7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("N7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal12 = worksheet.getCell("O7");
                worksheet.getCell("O7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("O7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal13 = worksheet.getCell("P7");
                worksheet.getCell("P7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("P7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal14 = worksheet.getCell("Q7");
                worksheet.getCell("Q7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("Q7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal15 = worksheet.getCell("R7");
                worksheet.getCell("R7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("R7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal16 = worksheet.getCell("S7");
                worksheet.getCell("S7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("S7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal17 = worksheet.getCell("T7");
                worksheet.getCell("T7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("T7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal18 = worksheet.getCell("U7");
                worksheet.getCell("U7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("U7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal19 = worksheet.getCell("V7");
                worksheet.getCell("V7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("V7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal20 = worksheet.getCell("W7");
                worksheet.getCell("W7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("W7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal21 = worksheet.getCell("X7");
                worksheet.getCell("X7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("X7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal22 = worksheet.getCell("Y7");
                worksheet.getCell("Y7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("Y7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal23 = worksheet.getCell("Z7");
                worksheet.getCell("Z7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("Z7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal24 = worksheet.getCell("AA7");
                worksheet.getCell("AA7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("AA7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal25 = worksheet.getCell("AB7");
                worksheet.getCell("AB7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("AB7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal26 = worksheet.getCell("AC7");
                worksheet.getCell("AC7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("AC7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal27 = worksheet.getCell("AD7");
                worksheet.getCell("AD7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("AD7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                const cellTanggal28 = worksheet.getCell("AE7");
                worksheet.getCell("AE7").alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
                worksheet.getCell("AE7").border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };

                worksheet.getRow(6).eachCell((cell) =>
                    Object.assign(cell, {
                        border: {
                            top: { style: "thin" },
                            left: { style: "thin" },
                            bottom: { style: "thin" },
                            right: { style: "thin" },
                        },
                    })
                );

                worksheet.getRow(7).eachCell((cell) =>
                    Object.assign(cell, {
                        border: {
                            top: { style: "thin" },
                            left: { style: "thin" },
                            bottom: { style: "thin" },
                            right: { style: "thin" },
                        },
                    })
                );
                const headerRowTipe = worksheet.getRow(5);
                headerRowTipe.height = 20;
                worksheet.mergeCells("A5:C5");

                headerRowTipe.getCell(1).value = "Tipe FORM: " + this.RESOURCE.LABEL;
                worksheet.getCell("A5:C5").font = {
                    name: "Arial Black",
                    size: 11,
                    color: "red",
                    verticalAlignment: "left",
                    horizontalAlignment: "left",
                };
                worksheet.getCell("A5:C5").alignment = {
                    horizontal: "left",
                    vertical: "middle",
                    wrapText: true,
                };
                worksheet.getColumn(36).numFmt = '#,#0.00"%"';

                cellTanggal1.value = "1";
                cellTanggal2.value = "2";
                cellTanggal3.value = "3";
                cellTanggal4.value = "4";
                cellTanggal5.value = "5";
                cellTanggal6.value = "6";
                cellTanggal7.value = "7";
                cellTanggal8.value = "8";
                cellTanggal9.value = "9";
                cellTanggal10.value = "10";
                cellTanggal11.value = "11";
                cellTanggal12.value = "12";
                cellTanggal13.value = "13";
                cellTanggal14.value = "14";
                cellTanggal15.value = "15";
                cellTanggal16.value = "16";
                cellTanggal17.value = "17";
                cellTanggal18.value = "18";
                cellTanggal19.value = "19";
                cellTanggal20.value = "20";
                cellTanggal21.value = "21";
                cellTanggal22.value = "22";
                cellTanggal23.value = "23";
                cellTanggal24.value = "24";
                cellTanggal25.value = "25";
                cellTanggal26.value = "26";
                cellTanggal27.value = "27";
                cellTanggal28.value = "28";

                //FOOTER
            }

            // // FOOTRER
            workbook.xlsx
                .writeBuffer()
                .then((buffer) => saveAs(new Blob([buffer]), `${this.export_name}.xlsx`))
                .catch((err) => console.log("Error writing excel export", err));
        },
    },
    data: function () {
        return {
            columns: [],
            rows: [],
            rowsXls: [],
            searchTerm: "",
            export_name: this.ExportExcelName,
            export_xlsx: this.ExportExcelName + ".xlsx",
            field_xlsx: {},
            balaidata: [],
            sumberdata: [],
        };
    },
    watch: {
        columns(val) {
            var columnCustome = {};
            for (var i in val) {
                var infoCol = val[i];
                var label = String(infoCol.label);
                if (infoCol.field == "progress") {
                    label = "Progress %";
                }
                if (infoCol.field != "station_id" && infoCol.field != "station_name" && infoCol.field != "periode" && infoCol.field != "progress") {
                    columnCustome["'" + label + "'"] = infoCol.field;
                } else {
                    columnCustome[label] = infoCol.field;
                }
            }
            this.field_xlsx = columnCustome;
            // var json_fields = {}

            //     var len_data = val.length;
            //     // day 28 = 32
            //     if(len_data < 33){
            //         json_fields = {
            //             "Station ID": "station_id",
            //             "Station Name": "station_name",
            //             "Periode": "periode",
            //             "01": "01",
            //             "02": "02",
            //             "03": "03",
            //             "04": "04",
            //             "05": "05",
            //             "06": "06",
            //             "07": "07",
            //             "08": "08",
            //             "09": "09",
            //             "10": "10",
            //             "11": "11",
            //             "12": "12",
            //             "13": "13",
            //             "14": "14",
            //             "15": "15",
            //             "16": "16",
            //             "17": "17",
            //             "18": "18",
            //             "19": "19",
            //             "20": "20",
            //             "21": "21",
            //             "22": "22",
            //             "23": "23",
            //             "24": "24",
            //             "25": "25",
            //             "26": "26",
            //             "27": "27",
            //             "28": "28",
            //             "Progress %": "progress"
            //         }
            //     // day 29 = 33
            //     }else if(len_data < 34){
            //         json_fields = {
            //             "Station ID": "station_id",
            //             "Station Name": "station_name",
            //             "Periode": "periode",
            //             "01": "01",
            //             "02": "02",
            //             "03": "03",
            //             "04": "04",
            //             "05": "05",
            //             "06": "06",
            //             "07": "07",
            //             "08": "08",
            //             "09": "09",
            //             "10": "10",
            //             "11": "11",
            //             "12": "12",
            //             "13": "13",
            //             "14": "14",
            //             "15": "15",
            //             "16": "16",
            //             "17": "17",
            //             "18": "18",
            //             "19": "19",
            //             "20": "20",
            //             "21": "21",
            //             "22": "22",
            //             "23": "23",
            //             "24": "24",
            //             "25": "25",
            //             "26": "26",
            //             "27": "27",
            //             "28": "28",
            //             "29": "29",
            //             "Progress %": "progress"
            //         }

            //     // day 30 = 34
            //     }else if(len_data < 35){
            //         json_fields = {
            //             "Station ID": "station_id",
            //             "Station Name": "station_name",
            //             "Periode": "periode",
            //             "01": "01",
            //             "02": "02",
            //             "03": "03",
            //             "04": "04",
            //             "05": "05",
            //             "06": "06",
            //             "07": "07",
            //             "08": "08",
            //             "09": "09",
            //             "10": "10",
            //             "11": "11",
            //             "12": "12",
            //             "13": "13",
            //             "14": "14",
            //             "15": "15",
            //             "16": "16",
            //             "17": "17",
            //             "18": "18",
            //             "19": "19",
            //             "20": "20",
            //             "21": "21",
            //             "22": "22",
            //             "23": "23",
            //             "24": "24",
            //             "25": "25",
            //             "26": "26",
            //             "27": "27",
            //             "28": "28",
            //             "29": "29",
            //             "30": "30",
            //             "Progress %": "progress"
            //         }

            //     // day 31 = 35
            //     }else if(len_data < 36){
            //         json_fields = {
            //             "Station ID": "station_id",
            //             "Station Name": "station_name",
            //             "Periode": "periode",
            //             "01": "01",
            //             "02": "02",
            //             "03": "03",
            //             "04": "04",
            //             "05": "05",
            //             "06": "06",
            //             "07": "07",
            //             "08": "08",
            //             "09": "09",
            //             "10": "10",
            //             "11": "11",
            //             "12": "12",
            //             "13": "13",
            //             "14": "14",
            //             "15": "15",
            //             "16": "16",
            //             "17": "17",
            //             "18": "18",
            //             "19": "19",
            //             "20": "20",
            //             "21": "21",
            //             "22": "22",
            //             "23": "23",
            //             "24": "24",
            //             "25": "25",
            //             "26": "26",
            //             "27": "27",
            //             "28": "28",
            //             "29": "29",
            //             "30": "30",
            //             "31": "31",
            //             "Progress %": "progress"
            //         }
            //     }
            //     console.log("JSON : ",json_fields);
            //    this.field_xlsx = json_fields;
        },
    },
};
</script>

<style>
td:has(.content-missing) {
    background: pink;
    font-size: 14px !important;
    text-align: center;
}

td:has(.content-late) {
    background: red;
    font-size: 14px !important;
    text-align: center;
    color: antiquewhite !important;
}

td:has(.content-early) {
    background: rgb(247, 122, 34);
    font-size: 14px !important;
    text-align: center;
    color: antiquewhite !important;
}

td:has(.content-unexpected) {
    background: cadetblue;
    font-size: 14px !important;
    text-align: center;
}

.icons-visual {
    font-size: 15px !important;
    display: block;
    text-align: center;
    font-weight: bold;
    margin: 5px -15px 8px 0px;
}

.monitor table.vgt-table td {
    padding: 10px !important;
    vertical-align: inherit !important;
}

.monitor .vgt-left-align {
    padding: 10px !important;
    vertical-align: inherit !important;
}

.monitor table.vgt-table {
    font-size: 12px !important;
}

/* button {
  padding: 10px 20px;
  border: 1px solid #ddd;
  color: #333;
  background-color: green;
  border-radius: 4px;
  font-size: 24px;
  font-weight: bold;
  font-family: "微软雅黑", arail;
  cursor: pointer;
} */
</style>
