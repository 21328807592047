const BASE_URL = process.env.VUE_APP_BASE_URL; // "http://116.90.165.45:7080/db/bmkgsatu/";

import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
    getMonitoringWAggregation(
        api,
        type_qry,
        type,
        filter,
        date_gte,
        date_lte,
        size
    ) {
        var url =
            Helper.getBaseUrl() +
            api +
            "?" +
            type_qry +
            "=" +
            type +
            filter +
            "&data_timestamp__gte=" +
            date_gte +
            "&data_timestamp__lte=" +
            date_lte +
            "&_size=" +
            size +
            "&_metadata=data_timestamp,uuid";
        return axios.get(url, Helper.getConfig());
    },
    getMonitoringFormHujan(date_gte, date_lte, size) {
        var url =
            Helper.getBaseUrl() +
            "/@poshujan_monitor?type_name=FormHujan&received_timestamp__gte=" +
            date_gte +
            "&received_timestamp__lte=" +
            date_lte +
            "&kabupaten_id=" +
            size +
            "&_metadata=id,uuid";
        return axios.get(url, Helper.getConfig());
    },

    getMonitoring(resource, region, province, kabupaten, period) {
        var url = "";

        var ids;
        let userLogin = JSON.parse(localStorage.getItem("userLogin"));
        if (userLogin.hasOwnProperty("groupIds")) {
            ids = userLogin.groupIds;
        }
        let userType = userLogin.username;
        if (userType == "root") {
            if (province == undefined) {
                url =
                    Helper.getBaseUrl() +
                    "@monitoring_data?periode=" +
                    period +
                    "&resource=" +
                    resource +
                    "&region=" +
                    region;
            } else if (kabupaten == undefined) {
                url =
                    Helper.getBaseUrl() +
                    "@monitoring_data?periode=" +
                    period +
                    "&resource=" +
                    resource +
                    "&region=" +
                    region +
                    "&province=" +
                    province;
            } else {
                url =
                    Helper.getBaseUrl() +
                    "@monitoring_data?periode=" +
                    period +
                    "&resource=" +
                    resource +
                    "&region=" +
                    region +
                    "&province=" +
                    province +
                    "&kabupaten=" +
                    kabupaten;
            }
        } else {
            if (province == undefined) {
                url =
                    Helper.getBaseUrl() +
                    "@monitoring_data?periode=" +
                    period +
                    "&resource=" +
                    resource +
                    "&region=" +
                    region +
                    "&station_id=" +
                    ids;
            } else if (kabupaten == undefined) {
                url =
                    Helper.getBaseUrl() +
                    "@monitoring_data?periode=" +
                    period +
                    "&resource=" +
                    resource +
                    "&region=" +
                    region +
                    "&province=" +
                    province +
                    "&station_id=" +
                    ids;
            } else {
                url =
                    Helper.getBaseUrl() +
                    "@monitoring_data?periode=" +
                    period +
                    "&resource=" +
                    resource +
                    "&region=" +
                    region +
                    "&province=" +
                    province +
                    "&kabupaten=" +
                    kabupaten +
                    "&station_id=" +
                    ids;
            }
        }

        return axios.get(url, Helper.getConfig());
    },
    getMonitoringKU(resource, stasiun, tahun, periode, id_sampel, progress) {
        var url =
            Helper.getBaseUrl() +
            "@monitoring_data?resource=" +
            resource +
            "&station_id=" +
            stasiun +
            "&tahun=" +
            tahun +
            "&periode=" +
            periode +
            "&id_sample=" +
            id_sampel +
            "&progress=" +
            progress;
        return axios.get(url, Helper.getConfig());
    },
    getMonitoringKU_v1(resource, stasiun, tahun, periode, id_sampel, progress) {
        var url =
            Helper.getBaseUrl() +
            "@monitoring_data?resource=" +
            resource +
            "&station_id=" +
            stasiun +
            "&tahun=" +
            tahun;
        // +"&periode="+periode+"&id_sampel="+id_sampel+"&progress="+progress;
        return axios.get(url, Helper.getConfig());
    },
    getMonitoringMonth(resource, region, province, kabupaten, period) {
        var url = "";
        if (province == undefined) {
            url =
                Helper.getBaseUrl() +
                "@monitoring_bulan?periode=" +
                period +
                "&resource=" +
                resource +
                "&region=" +
                region;
        } else if (kabupaten == undefined) {
            url =
                Helper.getBaseUrl() +
                "@monitoring_bulan?periode=" +
                period +
                "&resource=" +
                resource +
                "&region=" +
                region +
                "&province=" +
                province;
        } else {
            url =
                Helper.getBaseUrl() +
                "@monitoring_bulan?periode=" +
                period +
                "&resource=" +
                resource +
                "&region=" +
                region +
                "&province=" +
                province +
                "&kabupaten=" +
                kabupaten;
        }

        return axios.get(url, Helper.getConfig());
    },

    getMonitoringGcam(daterange, produk) {
        var url =
            Helper.getBaseUrl() +
            "@monitor-gcam?product=" +
            produk +
            "&data_timestamp__gte=" +
            daterange[0] +
            "&data_timestamp__lte=" +
            daterange[1];
        return axios.get(url, Helper.getConfig());
        // var url = "https://bmkgsatu.bmkg.go.id/db/bmkgsatu/@monitor-gcam?product="+produk+"&data_timestamp__gte="+daterange[0]+"&data_timestamp__lte="+daterange[1]
        // return axios.get(url, Helper.getConfigDirectTemp());
    },

    getMonitoringEcmwf(daterange, periode) {
        var url =
            Helper.getBaseUrl() +
            "@monitor-ecmwf?periode=" +
            periode +
            "&data_timestamp__gte=" +
            daterange[0] +
            "&data_timestamp__lte=" +
            daterange[1];
        return axios.get(url, Helper.getConfig());
        // var url = "https://bmkgsatu.bmkg.go.id/db/bmkgsatu/@monitor-ecmwf?periode="+periode+"&data_timestamp__gte="+daterange[0]+"&data_timestamp__lte="+daterange[1]
        // return axios.get(url, Helper.getConfigDirectTemp());
    },

    getMonitoringRason(daterange, stations) {
        var url =
            Helper.getBaseUrl() +
            "@monitor-rason?wmo_number=" +
            stations +
            "&data_timestamp__gte=" +
            daterange[0] +
            "&data_timestamp__lte=" +
            daterange[1];
        return axios.get(url, Helper.getConfig());
        // var url = "https://bmkgsatu.bmkg.go.id/db/bmkgsatu/@monitor-rason?wmo_number="+stations+"&data_timestamp__gte="+daterange[0]+"&data_timestamp__lte="+daterange[1]
        // return axios.get(url, Helper.getConfigDirectTemp());
    },

    getMonitoringSateliteHimawari(daterange, tipe_data, produk) {
        let rproduk = tipe_data + produk;
        var url =
            Helper.getBaseUrl() +
            "@monitor-satelit?tipe=" +
            tipe_data +
            "&data_timestamp__gte=" +
            daterange[0] +
            "&data_timestamp__lte=" +
            daterange[1] +
            "&produk=" +
            rproduk;
        return axios.get(url, Helper.getConfig());
        // var url = "https://bmkgsatu.bmkg.go.id/db/bmkgsatu/@monitor-satelit?tipe="+tipe_data+"&data_timestamp__gte="+daterange[0]+"&data_timestamp__lte="+daterange[1]+"&produk="+rproduk
        // return axios.get(url, Helper.getConfigDirectTemp());
    },

    getMonitoringRadar(daterange, location, produk) {
        var url =
            Helper.getBaseUrl() +
            "@monitor-radar?lokasi=" +
            location +
            "&data_timestamp__gte=" +
            daterange[0] +
            "&data_timestamp__lte=" +
            daterange[1] +
            "&produk=" +
            produk;
        return axios.get(url, Helper.getConfig());
        // var url = "https://bmkgsatu.bmkg.go.id/db/bmkgsatu/@monitor-radar?lokasi="+location+"&data_timestamp__gte="+daterange[0]+"&data_timestamp__lte="+daterange[1]+"&produk="+produk
        // return axios.get(url, Helper.getConfigDirectTemp());
    },

    getMonitoringGts(resource, region, province, period) {
        var url = "";

        var ids;
        let userLogin = JSON.parse(localStorage.getItem("userLogin"));
        if (userLogin.hasOwnProperty("groupIds")) {
            ids = userLogin.groupIds;
        }
        let userType = userLogin.username;
        if (userType == "root") {
            if (province == undefined) {
                url =
                    Helper.getBaseUrl() +
                    "@monitoring_gts?periode=" +
                    period +
                    "&type_message=" +
                    resource +
                    "&region=" +
                    region;
            } else {
                url =
                    Helper.getBaseUrl() +
                    "@monitoring_gts?periode=" +
                    period +
                    "&type_message=" +
                    resource +
                    "&region=" +
                    region +
                    "&province=" +
                    province;
            }
        } else {
            if (province == undefined) {
                url =
                    Helper.getBaseUrl() +
                    "@monitoring_gts?periode=" +
                    period +
                    "&type_message=" +
                    resource +
                    "&region=" +
                    region +
                    "&station_id=" +
                    ids;
            } else {
                url =
                    Helper.getBaseUrl() +
                    "@monitoring_gts?periode=" +
                    period +
                    "&type_message=" +
                    resource +
                    "&region=" +
                    region +
                    "&province=" +
                    province +
                    "&station_id=" +
                    ids;
            }
        }

        return axios.get(url, Helper.getConfig());
    },
};
